export const allBuildings = [
  ["", ""],
  ["ARTS", "Arts Building"],
  ["BIOEN", "Bioengineering"],
  ["BRDA", "Broida Hall"],
  ["BREN", "Bren Hall"],
  ["BSIF", "Bio Science Instructional Facility"],
  ["BUCHN", "Buchanan Hall"],
  ["CAMPB", "Campbell Hall"],
  ["CHEM", "Chemistry"],
  ["CRST", "College of Creative Studies"],
  ["ED", "Education"],
  ["ELLSN", "Ellison Hall"],
  ["ELNGS", "Ellings Hall"],
  ["EMBAR", "Embarcadero Hall"],
  ["ENGR2", "Engineering II"],
  ["ESB", "Engineering Science"],
  ["GIRV", "Girvetz Hall"],
  ["HARDR", "Harder Stadium"],
  ["HFH", "Harold Frank Hall"],
  ["HSSB", "Humanities and Social Sciences"],
  ["ICA", "Intercollegiate Athletics"],
  ["IV", "Isla Vista Theater"],
  ["KERR", "Kerr Hall"],
  ["LIB", "Library"],
  ["LSB", "Life Sciences"],
  ["MLAB", "Music Lab"],
  ["MUSIC", "Music"],
  ["NCEAS", "NCEAS"],
  ["NH", "North Hall"],
  ["NOBLE", "Noble Hall"],
  ["ON", "Online"],
  ["PHELP", "Phelps Hall"],
  ["PLLOK", "Pollock Theater"],
  ["PSB-N", "Physical Sciences North"],
  ["PSB-S", "Physical Sciences South"],
  ["PSY-E", "Psychology East"],
  ["PSYCH", "Psychology"],
  ["RECEN", "Recreational Center"],
  ["RGYM", "Robertson Gym"],
  ["SH", "South Hall"],
  ["SRB", "Student Resource Building"],
  ["SSMS", "Social Sciences & Media Studies"],
  ["TD-E", "Theater & Dance East"],
  ["TD-W", "Theater & Dance West"],
  ["TRACK", "Track Field"],
  ["WEBB", "Webb Hall"],
];

export const coursesInLib = [
  {
    _id: {
      timestamp: 1684366765,
      date: 1684366765000,
    },
    courseInfo: {
      quarter: "20221",
      courseId: "CHEM    184  -1",
      title: "CHEM LITERATURE",
      description:
        "Lectures and exercises on the literature and other information resources of use in chemistry.",
    },
    section: {
      enrollCode: "06619",
      section: "0100",
      session: null,
      classClosed: null,
      courseCancelled: null,
      gradingOptionCode: null,
      enrolledTotal: 19,
      maxEnroll: 24,
      secondaryStatus: null,
      departmentApprovalRequired: false,
      instructorApprovalRequired: false,
      restrictionLevel: null,
      restrictionMajor: null,
      restrictionMajorPass: null,
      restrictionMinor: null,
      restrictionMinorPass: null,
      concurrentCourses: ["CHEM    284  0100"],
      timeLocations: [
        {
          room: "1312",
          building: "LIB",
          roomCapacity: null,
          days: " T R   ",
          beginTime: "14:00",
          endTime: "15:15",
        },
      ],
      instructors: [
        {
          instructor: "HUBER C F",
          functionCode: "Teaching and in charge",
        },
      ],
    },
  },
  {
    _id: {
      timestamp: 1684366772,
      date: 1684366772000,
    },
    courseInfo: {
      quarter: "20221",
      courseId: "CHEM    284  -1",
      title: "CHEMICAL LITERATURE",
      description:
        "Lectures and exercises on the literature and other   information resources of use in chemistry.",
    },
    section: {
      enrollCode: "06817",
      section: "0100",
      session: null,
      classClosed: null,
      courseCancelled: null,
      gradingOptionCode: null,
      enrolledTotal: 2,
      maxEnroll: 5,
      secondaryStatus: null,
      departmentApprovalRequired: false,
      instructorApprovalRequired: false,
      restrictionLevel: null,
      restrictionMajor: null,
      restrictionMajorPass: null,
      restrictionMinor: null,
      restrictionMinorPass: null,
      concurrentCourses: ["CHEM    184  0100"],
      timeLocations: [
        {
          room: "1312",
          building: "LIB",
          roomCapacity: null,
          days: " T R   ",
          beginTime: "14:00",
          endTime: "15:15",
        },
      ],
      instructors: [
        {
          instructor: "HUBER C F",
          functionCode: "Teaching and in charge",
        },
      ],
    },
  },
];

export const oneBuilding = [["ARTS", "Arts Building"]];

export const threeBuildings = [
  ["ELLSN", "Ellison Hall"],
  ["ELNGS", "Ellings Hall"],
  ["EMBAR", "Embarcadero Hall"],
];
